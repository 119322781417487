import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Nav from "./Nav";
import Login from "./Login";
import Muns from "./Moons";
import ESIAdd from "./ESIAdd";

function Home() {
  return (
    <BrowserRouter>
      <Nav />
      <div className="container container-xl">
        <Switch>
          <Route path="/esi/add" component={ESIAdd} />
          <Route exact path="/" component={Login} />
          <Route exact path="/moons" component={Muns} />
          <Route>
            <h1>Not Found</h1>
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default Home;
