import React from 'react';
import ReactDOM from 'react-dom';
import Home from './Home';

// Pull in JS from bootstrap
import 'bootstrap'
import 'bootstrap/js/dist/modal'
import './styles/_theme.scss';


function App() {
  return <Home />
}

ReactDOM.render(<App />, document.getElementById('root'));