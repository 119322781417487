import React from "react";
import { Redirect, useHistory } from "react-router";
import { hasCookie } from "./utils";

function ESIAdd() {
  if (hasCookie()) {
    return <Redirect to="/moons"></Redirect>;
  }

  return (
    <div className="row justify-content-center">
      <a href="/oauth/begin" className="btn btn-lg btn-primary">
        Log into GICE
      </a>
    </div>
  );
}

export default ESIAdd;
