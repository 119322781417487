import Cookies from "js-cookie";

const moonOres = {
  Zeolites: "R4",
  Bitumens: "R4",
  Sylvite: "R4",
  Coesite: "R4",
  Titanite: "R8",
  Cobaltite: "R8",
  Euxenite: "R8",
  Scheelite: "R8",
  Chromite: "R16",
  Sperrylite: "R16",
  Vanadinite: "R16",
  Otavite: "R16",
  Carnotite: "R32",
  Cinnabar: "R32",
  Pollucite: "R32",
  Zircon: "R32",
  Loparite: "R64",
  Monazite: "R64",
  Xenotime: "R64",
  Ytterbite: "R64",
};

const rarityToColor = {
  R4: "secondary",
  R8: "primary",
  R16: "success",
  R32: "warning",
  R64: "danger",
};

const parseMoonText = (text) => {
  const data = [];

  if (!text) {
    return data;
  }

  text = text.replace("%", "");

  // Verify the data
  const split = text.split("\t");

  for (let i = 0; i < split.length; i++) {
    if (i % 2 == 1) {
      // moon ore
      data.push({
        name: split[i],
        rarity: moonOres[split[i]],
        color: rarityToColor[moonOres[split[i]]], // lol
        percentage: parseFloat(split[i + 1]),
      });
    } else {
      continue;
    }
  }

  return data;
};

export const hasCookie = () => {
  return !!Cookies.get("munbot_session");
};

export { parseMoonText };
