import React, { useState } from 'react'
import classNames from 'classnames'

function ActionButton(props) {
  const { apiRoute, method, text, onDone, color } = props
  const [inActon, setInAction] = useState(false)


  const makeCall = () => {
    if (inActon) {
      return
    }
    setInAction(true)

    fetch(apiRoute, {
      method: method || 'GET'
    })
    .then(resp => { 
      setInAction(false)
      onDone(resp.ok)
    })
    .catch(console.error)
  }

  let wholeColor = color
  const classes = classNames('btn', {[`btn-${wholeColor}`]: true})

  let innerHtml = text
  if (inActon) {
    innerHtml = (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    )
  }

  return (
  <button className={classes} onClick={makeCall}>{innerHtml}</button>
  )
}

export default ActionButton