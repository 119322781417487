import React, { useState, useEffect } from "react";
import classNames from "classnames";
import moment from "moment";
import ActionButton from "./ActionButton";
import Modal from "./Modal";
import { parseMoonText } from "./utils";
import { useHistory } from "react-router-dom";

function Muns() {
  const [error, setError] = useState("");

  const history = useHistory();

  const [recentFracks, setRecentFracks] = useState([]);

  const [moonToEdit, setMoonToEdit] = useState(false);
  const [moonData, setMoonData] = useState("");
  const onMoonDataSubmit = () => {
    const { id } = moonToEdit;

    fetch(`/api/moons/${id}/data`, {
      method: "POST",
      body: moonData,
    })
      .then((resp) => {
        return resp.text();
      })
      .then((text) => {
        if (text != "") {
          setError(text);
        } else {
          fetchMoons();
        }
        setMoonData("");
        setMoonToEdit(null);
      })
      .catch(console.error);
  };

  const onModalDismiss = () => {
    setMoonToEdit(null);
  };

  const [moons, setMoons] = useState([]);
  const [allMoons, setAllMoons] = useState(true);

  useEffect(() => {
    fetchMoons();
  }, [allMoons]);

  const fetchMoons = () => {
    fetch(`/api/moons?tracked=${!allMoons}`)
      .then((resp) => {
        if (resp.status == 401) {
          history.push("/");
          return;
        }

        if (!resp.ok) {
          setError("Unable to pull moon data. Please try refreshing");
          return;
        }

        return resp.json();
      })
      .then((data) => {
        setMoons(data.moons);

        if (data.recent_fracks) {
          setRecentFracks(data.recent_fracks);
        }
      })
      .catch(console.error);
  };

  const now = moment.utc();

  const rows = moons.map((m, i) => {
    const {
      name,
      chunk_arrival_time,
      extraction_start_time,
      id,
      tracked,
      moon_data,
      last_frack_value,
      last_frack_link,
      fuel_expires,
    } = m;
    const m_arrival = moment.utc(chunk_arrival_time);
    const m_fuel_expires = moment.utc(fuel_expires);
    const m_extraction_start_time = moment.utc(extraction_start_time);

    const duration = moment.duration(m_arrival.diff(now));

    const data = parseMoonText(moon_data);
    const dataRows = data.map((r, idx) => {
      return (
        <tr key={idx}>
          <td>{r.name}</td>
          <td>
            <span className={`badge badge-${r.color}`}>{r.rarity}</span>
          </td>
          <td>{r.percentage + "%"}</td>
        </tr>
      );
    });

    let composition = "No data set";
    if (data.length > 0) {
      composition = (
        <table>
          <tbody>{dataRows}</tbody>
        </table>
      );
    }

    let frackValue;
    if (data.length > 0) {
      frackValue = (
        <a href={`/api/moons/${id}/eveprasial`} className="btn btn-danger">
          Force Regenerate Value
        </a>
      );
    }

    if (last_frack_value) {
      const format = new Intl.NumberFormat("ISL").format(last_frack_value);
      frackValue = (
        <div>
          {format} ISK <br /> <br />
          <a href={last_frack_link} target="_blank">
            {last_frack_link}
          </a>
          <br />
          <br />
          <a href={`/api/moons/${id}/eveprasial`} className="btn btn-danger">
            Force Regenerate Value
          </a>
        </div>
      );
    }

    return (
      <tr key={i}>
        <td>{name}</td>
        <td>{composition}</td>
        <td>{frackValue}</td>
        <td>{m_arrival.diff(m_extraction_start_time, "days")} days</td>
        <td>
          <b>EVE</b>
          <br /> {m_arrival.format("ddd, MMM Do, H:mm")}
          <br />
          <b>Local</b> <br /> {m_arrival.local().format("ddd, MMM Do, h:mm a")}
        </td>
        <td>{duration.humanize(true)}</td>
        <td>{m_fuel_expires.fromNow()}</td>
        <td>
          <div className="btn-group" role="group">
            <ActionButton
              color={tracked ? "warning" : "primary"}
              apiRoute={`/api/moons/${id}/toggle`}
              method="POST"
              onDone={fetchMoons}
              text={tracked ? "Untrack" : "Track"}
            />
            <button
              className="btn btn-secondary"
              onClick={() => setMoonToEdit(m)}
            >
              Submit Moon Data
            </button>
          </div>
        </td>
      </tr>
    );
  });

  const recentRows = recentFracks.map((f, i) => {
    const moon = moons.find((m) => m.id == f.id);

    if (!moon) {
      return null;
    }

    return (
      <tr key={i}>
        <td>{moon.name}</td>
        <td>{moon.composition}</td>
        <td>{f.frack_time} hours</td>
        <td>{f.frack_value}</td>
        <td>{f.frack_size}</td>
      </tr>
    );
  });

  let errorDiv;
  if (error) {
    errorDiv = (
      <div className="padding">
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      </div>
    );
  }

  let color = "primary";
  if (allMoons) {
    color = "info";
  }
  const classes = classNames("btn", { [`btn-${color}`]: true });

  return (
    <div className="row">
      <div className="col-12">
        {errorDiv}

        <Modal
          open={!!moonToEdit}
          title="Submit Mun Data"
          onSubmit={onMoonDataSubmit}
          onDismiss={onModalDismiss}
        >
          <textarea
            className="form-control"
            rows={25}
            placeholder="Paste moon data here"
            value={moonData}
            onChange={(e) => setMoonData(e.target.value)}
          />
        </Modal>

        <h3>Recently Fracked</h3>
        <table className="table table-bordered table-striped table-hover">
          <thead>
            <tr>
              <th>Mun</th>
              <th>Ores</th>
              <th>Frack Time</th>
              <th>Frack Value</th>
              <th>Frack Size</th>
            </tr>
          </thead>
          <tbody>{recentRows}</tbody>
        </table>

        <h3>Moon List</h3>
        <div className="padding">
          <div className="btn-group" role="group" aria-label="Basic example">
            <button className={classes} onClick={() => setAllMoons(!allMoons)}>
              {allMoons ? "Tracked Muns" : "All Muns"}
            </button>
          </div>
        </div>
        <table className="table table-bordered table-striped table-hover">
          <thead>
            <tr>
              <th>Mun</th>
              <th>Ores</th>
              <th>Frack Value</th>
              <th>Frack Size</th>
              <th>Arrival Time</th>
              <th>Relative Time</th>
              <th>Fuel Expires</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </div>
  );
}

export default Muns;
