import React from 'react'
import classNames from 'classnames'

function Modal(props) {
  const { open, onSubmit, onDismiss, title } = props
  const modalClasses = classNames("modal", "fade", {"show": open})

  return (
    <div className={modalClasses} data-backdrop="static" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
          </div>
          <div className="modal-body">
            {props.children}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onDismiss}>Close</button>
            <button type="button" className="btn btn-primary" onClick={onSubmit}>Save changes</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal