import React from 'react'

function ESIAdd() {
  return (
    <div className="row justify-content-center">
      <div className="col-5">
        <h1>Add ESI Key Below</h1>
        <a href="/api/esi/add">
          <img src="https://web.ccpgamescdn.com/eveonlineassets/developers/eve-sso-login-white-large.png"></img>
        </a>
      </div>
    </div>
  )
}

export default ESIAdd